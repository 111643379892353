svg4everybody();

$(document).ready(function(){
    // Form fields
    $('.formControl').on('blur', function(){
        ($(this).val())
            ? $(this).next('label').addClass('active')
            : $(this).next('label').removeClass('active');
    });

    //Pop up feedback form
    $('.orderTop, .orderLeft').on('click', function(e) {
        e.preventDefault();
        $('.popupWrap').css('transform','scale(0, 0)');
        $('.popupBack').css('display','flex');
        $('.main').css({'height':'100%', 'overflow':'hidden'});
        $('.popupWrap').css('transform','scale(1, 1)');
    });
    $('#popup>.popupWrap>.close').on('click', function() {
        $('.popupWrap').css('transform','scale(0, 0)');
        $('.main').css({'height':'auto', 'overflow':'auto'});
        setTimeout(function() {
            $('.popupBack').css('display','none');
        }, 500);
    });

    // Left menu
    $('.menuLeft>h3').click(function() {
        if ($(window).width() < '768') {
            $('.menuPortfolio').slideToggle(500);
        }
    });


    // Resize window
    $(window).on('resize',windowSize);


    // Fancybox
    if ($.fn.fancybox) {
        $('[data-fancybox="portfolio"]').fancybox();
    }

    // Slick
    if ($.fn.slick) {
        $('.productImg').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,

            responsive: [{

                breakpoint: 1023,
                settings: {
                    arrows: true
                }

            }]
        });
        $('.thumbnail').on('click', function() {
            $('.productImg').slick('slickGoTo',$(this).index(), true);
        });

        // Clients
        $('.clientsLogo').slick({
            infinite: true,
            slidesToShow: 3,
            autoplay: true,
            autoplaySpeed: 0,
            speed: 5000,
            arrows: false,
            cssEase:'linear',
            centerMode: false,
            pauseOnHover: false,
            pauseOnFocus: false,
            focusOnSelect: false
        });
        $('.clientsLogo2').slick({
            infinite: true,
            slidesToShow: 3,
            autoplay: true,
            autoplaySpeed: 0,
            speed: 4000,
            arrows: false,
            centerMode: true,
            cssEase:'linear',
            pauseOnHover: false,
            pauseOnFocus: false,
            focusOnSelect: false,
            initialSlide: 3
        });

        // Similar equipment & services
        $('.carousel-similar').slick({
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 3,
            autoplay: true,
            autoplaySpeed: 5000,
            arrows: true,

            responsive: [
                {
                    breakpoint: 1023,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 479,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
             ]
        });

        // Demonstrate equipment working
        $('.carousel-show').slick({
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 3,
            autoplay: true,
            autoplaySpeed: 4000,
            speed: 500,
            arrows: true,

            responsive: [
                {
                    breakpoint: 1023,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 479,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });

    }

    // Resize window function
    function windowSize() {
        if ($(window).width() > '767') {
            $('.menuPortfolio').css("display", "block");
        } else {
                $('.menuPortfolio').css("display", "none");
        }
    }
});